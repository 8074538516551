import React from 'react';
import './style.scss';
const Button = ({ children, ...props }) => {
  return (
    <a href="https://form.respondi.app/6hrXBeco" className="btn"  target="_blank" {...props}>
      {children}
    </a>
  );
};

export default Button;
