import React from 'react';

import './style.scss';
import Button from '../../Buttons/Button';

const SeventhSection = () => {
  return (
    <section id="seventh-section">
      <h3 style={{ '--delay': '0.5s' }}>Modalidades Disponíveis</h3>
      <p>
      Conecte-se o seu e-commerce a profissionais com conhecimento em otimização de tráfego e mídia, copywriting, design, estratégias de venda e visão de negócio para impulsionar sua loja online. 
      </p>
      <ul>
        <li>
          <h4>Consultoria Encontro Único</h4>
          <ul>
            <li>Análise de oportunidades</li>
            <li>Apresentação do Plano de Ação Prático</li>
            <li>Um encontro estratégico</li>
            <li>Encontro Gravado para Assistir depois</li>
            <li>Suporte e Acompanhamento por 20 dias</li>
          </ul>
        </li>
        <li>
          <h4>Okape E-commerce Escalável</h4>
          <p>
          Tenha o time da Okape cuidando do Marketing e Vendas do seu E-commerce. Aqui você terá o nosso acompanhamento diário de venda, campanhas, novos canais, direcionamento sobre artes e vídeos que vendem e análise de dados para aumentar os seus lucros.
          </p>
        </li>
        <li>
          <h4>Consultoria E-Commerce Premium</h4>
          <ul>
            <li>Quatro encontros estratégicos</li>
            <li>Análise de oportunidades</li>
            <li>Apresentação do Plano de Ação Prático</li>
            <li>Encontro Gravado para Assistir depois</li>
            <li>Suporte e Acompanhamento por 2 meses</li>
          </ul>
        </li>
      </ul>
      <Button>Falar com um Especialista</Button>
    </section>
  );
};

export default SeventhSection;
