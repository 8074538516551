import React from 'react';

import './style.scss';
const FourthSection = () => {
  return (
    <section id="fourth-section">
      <h3 style={{ '--delay': '0.5s' }}>
        Um olhar 360° e completo para dar o próximo passo no seu e-commerce
      </h3>
      <p style={{ '--delay': '0.7s' }}>
        iremos fazer mais do que anúncios online para sua loja
      </p>
      <ul>
        <li className="up-anim" style={{ '--delay': '0.4s' }}>
          <h4>Visão de negócio</h4>
          <p>
            Vamos te manter atualizado sobre as principais tendências do
            e-commerce, concorrência, novas ferramentas, estudo de mercado e
            muito mais.
          </p>
        </li>
        <li className="up-anim" style={{ '--delay': '0.4s' }}>
          <h4>Data Science</h4>
          <p>
            Analisaremos diariamente todas as ações implementadas para definir o
            que continua e o que sai do ar, com foco exclusivamente em aumentar
            seus lucros.
          </p>
        </li>
        <li className="up-anim" style={{ '--delay': '0.4s' }}>
          <h4>Marketing</h4>
          <p>
            Aqui vamos te ajudar a definir ou otimizar seus canais de divulgação
            online, entender ainda mais sobre a segmentação que mais gera venda
            para o seu e-commerce.
          </p>
        </li>
      </ul>
    </section>
  );
};

export default FourthSection;
