import React from 'react';

import './style.scss';
import Button from '../../Buttons/Button';

const EighthSection = () => {
  return (
    <section id="eighth-section">
      <ul>
        <li>
          <h3 style={{ '--delay': '0.5s' }}>
            Quem é a <strong>OKAPE</strong> e porque ela é tão importante para o
            seu E-commerce?
          </h3>
          <p>
            A Okape começou com um sonho de Paulo Lima de transformar o cenário
            brasileiro de comércio eletrônico através da consultoria
            especializada para aumento de venda e lucratividade dos lojistas
            online.
          </p>
          <p>
            Depois de anos atuando no mercado online e em especial no segmento
            de e-commerce, ele percebeu que o setor de e-commerce no Brasil
            tinha um enorme potencial de crescimento, no entanto, havia uma
            falta de direcionamentos práticos sobre como transformar as
            oportunidades existentes no mercado em ações reais de venda.
          </p>
          <p>
            Foi assim que nasceu a Okape, com acompanhamentos específicos
            voltados para esse mercado, onde realizamos nossas entregas tanto de
            forma presencial ou online.
          </p>
          <p>
            Através das experiências vividas no e-commerce, nós desenvolvemos
            práticas eficazes e adaptadas para o setor. Nosso foco principal é o
            crescimento exponencial e sustentável das empresas de e-commerce.
          </p>
          <p>
            Nossa missão é clara: queremos que qualquer e-commerce, não importa
            o nicho, alcance um crescimento surpreendente e gere alto
            faturamento em curto espaço de tempo.
          </p>
        </li>
      </ul>
      
    </section>
  );
};

export default EighthSection;
