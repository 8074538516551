import React from 'react';
import './style.scss';
const Header = () => {
  return (
      <header>
        
      

      <article>
        <span className="logo">OKAPE </span>
        <span>Consultoria </span>
      </article>
    </header>
  );
};

export default Header;
