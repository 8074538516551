import React from 'react';

import './style.scss';
import Button from '../../Buttons/Button';

const NinthSection = () => {
  return (
    <section id="ninth-section">
      <article>
        <h3 style={{ '--delay': '0.5s' }}>Ainda com dúvidas?</h3>
        <p style={{ '--delay': '0.7s' }}>
          Fale agora mesmo com a nossa equipe por WhatsApp e tire todas as suas
          dúvidas com relação a consultoria.
        </p>
        <Button>Tirar dúvidas</Button>
      </article>
    </section>
  );
};

export default NinthSection;
