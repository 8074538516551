import React from 'react';

import './style.scss';
import Topic from './Topic';

import icon1 from '../../../assets/img/icons/1.png';
import icon2 from '../../../assets/img/icons/2.png';
import icon3 from '../../../assets/img/icons/3.png';
import icon4 from '../../../assets/img/icons/4.png';
import Button from '../../Buttons/Button';
const SixthSection = () => {
  const topics = [
    {
      image: icon1,
      title: 'DIAGNÓSTICO',
      description:
        'Iremos entender sobre sua loja, coletar o máximo de informações, mapear as necessidades e demonstrar as oportunidades de venda.  ',
    },
    {
      image: icon2,
      title: 'Plano de ação',
      description:
        'A partir das informações, necessidades e oportunidades mapeadas, vamos implementar ações de marketing e vendas para alcançar resultados consistentes.',
    },
    {
      image: icon3,
      title: 'Mensuração',
      description:
        'Faremos uma avaliação detalhada do que foi implementado, realizaremos pequenos ajustes se necessário e a partir disso, iremos propor novas ideias e ações.',
    },
    {
      image: icon4,
      title: 'ACOMPANHAMENTO',
      description:
        'Nessa etapa do processo, faremos a manutenção das ações para garantir que tudo esteja saindo conforme o esperado e realizaremos novos diagnósticos.',
    },
  ];

  return (
    <section id="sixth-section">
      <h3>
      Qual a forma Okape de trabalhar seu<strong> E-commerce?</strong>
      </h3>
      <h4>
      Metodologia totalmente focada para fazer seu e-commerce vender mais e acesso a profissionais com anos de experiência.
      </h4>
      <p>
      Iremos analisar detalhadamente o seu cenário para implementar e direcionar a sua loja online para ações práticas de venda.
      </p>

      <ul>
        {topics.map((topic, index) => (
          <li
            className={`up-anim ${index % 2 ? 'right' : ''} `}
            style={{ '--delay': `0.${index + 1}s` }}
          >
            <Topic {...topic} />
          </li>
        ))}
      </ul>
      <Button>Entre em Contato</Button>
    </section>
  );
};

export default SixthSection;
