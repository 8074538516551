import React from 'react';

const Topic = ({ image, title, description }) => {
  return (
    <figure>
      <h3>{title}</h3>
      <img src={image} alt={title} />
      <figcaption>
        <p>{description}</p>
      </figcaption>
    </figure>
  );
};

export default Topic;
