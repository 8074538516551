import React from 'react';
import Header from '../components/header/Header';
import FirstSection from '../components/sections/First/FirstSection';

import Footer from '../components/footer/Footer';
import Divider from '../components/content/Divider';
import SecondSection from '../components/sections/SecondSection/SecondSection';
import ThirdSection from '../components/sections/ThirdSection/ThirdSection';
import FourthSection from '../components/sections/FourthSection/FourthSection';
import FifthSection from '../components/sections/FifthSection/FifthSection';
import SixthSection from '../components/sections/SixthSection/SixthSection';
import SeventhSection from '../components/sections/SeventhSection/SeventhSection';
import EighthSection from '../components/sections/EighthSection/EighthSection';
import NinthSection from '../components/sections/NinthSection/NinthSection';

const Home = () => {
  return (
    <>
      <Header />
      <main>
        <FirstSection />
        <SecondSection />
        <ThirdSection />
        <FourthSection />
        <FifthSection />
        <SixthSection />
        <SeventhSection />
        <EighthSection />
        <NinthSection />
      </main>
      <Footer />
    </>
  );
};

export default Home;
