import React from 'react';

import './style.scss';
import Button from '../../Buttons/Button';

const SecondSection = () => {
  return (
    <section id="second-section">
      <div>
        
        <h2 style={{ '--delay': '0.5s' }}>
          Todo dono ou gerente de <br></br>e-commerce já passou por isso, ou vai
          passar...
        </h2>
        <p style={{ '--delay': '0.7s' }}>
          Se você sente algumas dessas dores ou tem receio que possa acontecer
          contigo, nós podemos te ajudar.
        </p>
        <span className="blue-block" style={{ '--delay': '0.4s' }}>
          Não consegue bater as metas estabelecidas, pois os seus profissionais
          de marketing só fazem mais do mesmo.
        </span>
        <ul>
          <li className="up-anim" style={{ '--delay': '0.4s' }}>
            Percebe que precisa achar um diferencial, pois os Marketplaces
            (Mercado Livre, Amazon) estão cada vez mais pegando espaço e tudo
            virou uma guerra de preço.
          </li>
          <li className="up-anim" style={{ '--delay': '0.4s' }}>
            Não consegue ter uma previsibilidade de receita e isso te tira o
            sono todos os dias, pois não sabe qual caminho trilhar e o que deve
            ser feito.
          </li>
          <li className="up-anim" style={{ '--delay': '0.4s' }}>
            {' '}
            Constatou que está cada vez mais caro anunciar e não sabe como criar
            estratégias que otimizem os seus custos e aumentem suas receitas.
          </li>
          <li className="up-anim" style={{ '--delay': '0.4s' }}>
            Contratar agências que vendem sonhos, mas nunca te entregam
            resultados ou não estão preocupados com sua loja.
          </li>
          <li className="up-anim" style={{ '--delay': '0.4s' }}>
            Sentir que a concorrência está sempre a frente, mesmo que você se
            esforce e tente se diferenciar.
          </li>
          <li className="up-anim" style={{ '--delay': '0.4s' }}>
            Mudanças e novidades constantes que surgem no mercado de e-commerce
            e você não sabe como faz para aproveitar aquelas oportunidades.
          </li>
        </ul>
          <Button>Fale Agora Conosco</Button>
        
      </div>
    </section>
    
  );
};


export default SecondSection;
