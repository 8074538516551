import React from 'react';

import './style.scss';
const ThirdSection = () => {
  return (
    <section id="third-section">
      <h3 style={{ '--delay': '0.5s' }}>
        Quando <strong>recomendamos</strong> a OKAPE?
      </h3>

      <ul>
        <li className="up-anim" style={{ '--delay': '1.1s' }}>
          <h4>Escalando seu e-commerce
</h4>
          <p>
          Se você está em um estágio de crescimento e busca otimizar os processos de performance, marketing e vendas para expandir sua loja de maneira sustentável.
          </p>
        </li>
        <li className="up-anim" style={{ '--delay': '1.2s' }}>
          <h4>E-commerce com piora nos resultados</h4>
          <p>
          Se seus resultados estão aquém do esperado, é ideal uma análise para identificarmos os pontos de melhoria e propormos soluções estratégicas para reverter esse cenário.
          </p>
        </li>
        <li className="up-anim" style={{ '--delay': '1.3s' }}>
          <h4>Lançamento do Seu E-commerce</h4>
          <p>
          Aqui é o momento ideal para estruturar todos os processos de performance, marketing e vendas para que sua loja cresça de maneira lucrativa.
          </p>
        </li>
        <li className="up-anim" style={{ '--delay': '1.4s' }}>
          <h4>E-commerce precisa de análise geral
</h4>
          <p>        
Com uma abordagem 360°, vamos examinar desde a experiência do usuário, concorrência até a comunicação e canais de marketing, para propor soluções de otimização.

          </p>
        </li>
      </ul>
    </section>
  );
};

export default ThirdSection;
