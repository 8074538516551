import React from 'react';

import './style.scss';
import Button from '../../Buttons/Button';
const FirstSection = () => {
  return (
    <section id="first" className="visible">
      <article>
        <h1 style={{ '--delay': '.1s' }}>
        Diagnóstico Completo do Processo de Marketing e Vendas<br></br>do Seu E-commerce
        </h1>
        <p style={{ '--delay': '.3s' }}>
        Nós iremos analisar de forma gratuita o seu e-commerce e criar um plano de ação personalizado para você bater as suas metas todos os meses.
        </p>
        <Button>AGENDAR DIAGNÓSTICO GRATUITO</Button>
      </article>
      <aside />
    </section>
  );
};

export default FirstSection;
