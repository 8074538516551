import React from 'react';

import './style.scss';
import tdesktop from '../../../assets/img/table-desktop.png';
import tmobile from '../../../assets/img/table-mobile.png';
import Button from '../../Buttons/Button';

const FifthSection = () => {
  return (
    <section id="fifth-section">
      <h3 style={{ '--delay': '0.5s' }}>
        Veja como a <strong>OKAPE</strong> faz tudo que as agências tradicionais
        fazem e ainda vai além...
      </h3>
      <img src={tdesktop} alt="comparação" className="tdesktop" />
      <img src={tmobile} alt="comparação" className="tmobile" />
      <Button>Quero a Consultoria Okape </Button>
    </section>
    
  );
};

export default FifthSection;
