import React from 'react';
import { Link } from 'react-router-dom';
import './Obrigado.css';

function Obrigado() {
    return (
      <div className="obrigado-container">
        <h1 className="obrigado-heading">Sua Solicitação de Diagnóstico foi Enviada!</h1>
        <p className="obrigado-text">
          Agradecemos pelo interesse em melhorar as vendas do seu E-commerce! O mais breve possível nossa equipe comercial entrará em contato com você.
        </p>
        <Link to="/" className="obrigado-button">Voltar para a Home</Link>
      </div>
    );
  }

export default Obrigado;