import React from 'react';
import './style.scss';

const Footer = () => {
  return (
    <footer>
      <span>Copyright © 2023 - OKAPE. Todos os direitos reservados</span>
    </footer>
  );
};

export default Footer;
